import "./bootstrap";
import "../css/app.css";
import "tabulator-tables/dist/css/tabulator_site_dark.min.css";

import { createApp } from "vue";
import router from "./routers/index";
import { createPinia } from "pinia";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import App from "./App.vue";

createApp(App).use(createPinia()).use(router).use(Toast).mount("#app");
