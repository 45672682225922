import { defineStore } from "pinia";

export const useLoaderStore = defineStore("loader", {
    state: () => ({
        isLoading: false,
        actualProgress: 0,
        displayProgress: 0,
    }),
    actions: {
        startLoading() {
            this.isLoading = true;
            this.actualProgress = 0;
            this.displayProgress = 0;
            this.animateProgress();
        },
        updateProgress(progress) {
            this.actualProgress = Math.min(Math.max(progress, 0), 99);
        },
        finishLoading() {
            this.actualProgress = 100;
            this.isLoading = false;
        },
        animateProgress() {
            const animate = () => {
                if (this.displayProgress < this.actualProgress) {
                    this.displayProgress = Math.min(
                        this.displayProgress + 1,
                        this.actualProgress
                    );
                    requestAnimationFrame(animate);
                } else if (this.actualProgress === 100) {
                    this.displayProgress = 100;
                }
            };
            requestAnimationFrame(animate);
        },
    },
});
