import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../pages/Home.vue"),
        meta: {
            title: "Home",
            requiresAuth: false,
        },
    },
    {
        path: "/map",
        name: "Map",
        component: () => import("../pages/MapView.vue"),
        meta: {
            title: "Map",
            requiresAuth: false,
        },
    },
    {
        path: "/report",
        name: "Report",
        component: () => import("../pages/Report.vue"),
        meta: {
            title: "Report",
            requiresAuth: false,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem("token") === null) {
            next({
                name: "Home",
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
