<template>
    <nav
        :class="[
            'transition-all duration-300 ease-in-out',
            'sticky top-0 z-50 shadow-lg bg-[#121212] border-b-2 border-b-[#3FB448]',
            { 'py-2': !isScrolled, 'py-1': isScrolled },
        ]"
    >
        <div class="px-4 mx-auto sm:px-6 lg:px-8">
            <div class="flex items-center justify-between">
                <div class="flex items-center flex-shrink-0 gap-2">
                    <img
                        :class="[
                            'transition-all duration-300 ease-in-out',
                            {
                                'w-12 h-12': !isScrolled,
                                'w-8 h-8': isScrolled,
                            },
                        ]"
                        src="https://res.cloudinary.com/killtdj/image/upload/v1719635543/Kabupaten_Belitung_paacyj.png"
                        alt="Logo"
                    />
                    <div class="flex flex-col text-xs text-white">
                        <p class="text-sm font-bold">SIMTARU</p>
                        <p>Kabupaten Belitung</p>
                    </div>
                </div>

                <div class="items-center hidden h-full md:flex">
                    <div class="flex items-baseline space-x-4 cursor-pointer">
                        <router-link
                            v-for="item in navItems"
                            :key="item.name"
                            :to="item.href"
                            custom
                            v-slot="{ navigate, isActive }"
                        >
                            <a
                                @click="navigate"
                                @keypress.enter="navigate"
                                role="link"
                                :class="[
                                    isActive
                                        ? 'bg-[#3FB448] text-white font-bold'
                                        : 'text-indigo-200 hover:text-[#3FB448]',
                                    'px-3 py-2 font-medium transition duration-150 ease-in-out',
                                    {
                                        'text-sm': !isScrolled,
                                        'text-xs': isScrolled,
                                    },
                                ]"
                            >
                                {{ item.name }}
                            </a>
                        </router-link>
                    </div>
                </div>

                <!-- Mobile menu button -->
                <div class="flex md:hidden">
                    <button
                        @click="mobileMenuOpen = !mobileMenuOpen"
                        type="button"
                        class="inline-flex items-center justify-center p-2 text-indigo-200 bg-[#121212] rounded-md hover:text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                    >
                        <!-- Button content -->
                    </button>
                </div>
            </div>
        </div>

        <div v-show="mobileMenuOpen" class="md:hidden" id="mobile-menu">
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <router-link
                    v-for="item in navItems"
                    :key="item.name"
                    :to="item.href"
                    custom
                    v-slot="{ navigate, isActive }"
                >
                    <a
                        @click="navigate"
                        @keypress.enter="navigate"
                        role="link"
                        :class="[
                            isActive
                                ? 'bg-indigo-700 text-white'
                                : 'text-indigo-200 hover:bg-indigo-500 hover:text-white',
                            'block px-3 py-2 rounded-md text-base font-medium',
                        ]"
                    >
                        {{ item.name }}
                    </a>
                </router-link>
            </div>
        </div>
    </nav>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
    setup() {
        const mobileMenuOpen = ref(false);
        const isScrolled = ref(false);
        const navItems = [
            { name: "Beranda", href: "/" },
            { name: "Peta", href: "/map" },
            // { name: "Report", href: "/report" },
        ];

        const handleScroll = () => {
            if (window.scrollY > 0) {
                isScrolled.value = false;
            } else {
                isScrolled.value = false;
            }
        };

        onMounted(() => {
            window.addEventListener("scroll", handleScroll);
        });

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll);
        });

        return {
            mobileMenuOpen,
            navItems,
            isScrolled,
        };
    },
};
</script>
