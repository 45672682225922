<!-- components/Preloader.vue -->
<template>
    <div v-if="loaderStore.isLoading" class="preloader">
        <div class="spinner"></div>
    </div>
</template>

<script setup>
import { useLoaderStore } from "../stores/loaderStore";

const loaderStore = useLoaderStore();
</script>

<style scoped>
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.progress {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
